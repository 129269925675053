import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Blog Website Template | Blog Design Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/blog-website/"
    metaDescription="A blog website design template to help you bring your blog to life. Use it as it is or adapt to suit your own vision. Try now with Uizard Pro."
    description="
    h2:Bring your blog to life with our blog design template for web
    <br/>
    Working on a blog mockup for a client or for your website? Our blog UI mockup template is here to fast-track your design flow. Packed with all the core UX features that any self-respecting blog should include, you can use our <a:https://uizard.io/templates/>UI template</a> as it is or adapt it to suit your vision. 
    <br/>
    h3:A customizable blog example for all your future blog design projects
    <br/>
    With Uizard, customization is easy. Our easy-to-use design tool is powered by AI, making the design process faster than ever before. Auto-generate text, import hand-drawn wireframes, you can even convert screenshots of other blogs into editable mockups. Bring your idea to life with our <a:https://uizard.io/ai-design/>AI web design</a> features today.
    <br/>
    h3:Blog UI design with your team
    <br/>
    Take advantage of Uizard's smart collaboration features for a streamlined design experience. Invite your team members to collab on your blog web design or share a preview of your <a:https://uizard.io/mockups/>UI mockup</a> with stakeholders to get crucial early-stage design feedback.
    "
    pages={[
      "Blog website landing page mockup with all the core UX and navigation features",
      "Article landing page with core information segments and author info mockup",
      "Category landing page with article feed",
      "Footer navigation mockup with newsletter sign-up example",
    ]}
    projectCode="Z8mBy9woXrhaJO9Rj0dL"
    img1={data.image1.childImageSharp}
    img1alt="food blog website design template cover"
    img2={data.image2.childImageSharp}
    img2alt="food blog website design template category screen"
    img3={data.image3.childImageSharp}
    img3alt="food blog website design template search screen"
    img4={data.image4.childImageSharp}
    img4alt="food blog website design template article screen"
    img5={data.image5.childImageSharp}
    img5alt="food blog website design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/blog-website/food-blog-web-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/blog-website/food-blog-web-categories.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/blog-website/food-blog-web-search.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/blog-website/food-blog-web-article.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/blog-website/food-blog-web-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
